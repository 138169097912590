<template>
  <div class="manage-agency-users-edit animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-3">
          <h2 class="mb-5">Edit Developer User</h2>

          <button
            type="button"
            class="btn main bordered mb-3"
            @click="changePasswordModal.isShown = true"
          >
            Change Password
          </button>

          <fd-form-section title="Company Information">
            <div class="row col-12">
              <display-data
                class="px-1 mb-2"
                label="Developer Company"
                :content="developerUser.developer.name"
              >
              </display-data>
            </div>
            <fd-select
              v-model="projectMarketingStaff.roleId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Role"
              :options="roleOptions"
              selectText="Choose a role"
              :disabled="isRoleEditable"
              :validators="[validator.required]"
            >
            </fd-select>
          </fd-form-section>

          <fd-form-section title="Personal Info">
            <fd-input
              v-model="projectMarketingStaff.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="projectMarketingStaff.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <tel-input
              ref="tel"
              v-model="phoneInput"
              :tel.sync="projectMarketingStaff.phoneNo"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
          </fd-form-section>

          <div class="text-right p-2">
            <button class="btn main">Update</button>
          </div>
        </div>
      </fd-form>
    </div>
    <!-- Modal: Change Password -->
    <modal v-model="changePasswordModal.isShown">
      <fd-form class="change-password card p-3" @submit="changePassword">
        <h3 class="mb-3">Change Password</h3>

        <fd-input
          v-model="changePasswordModal.form.password"
          class="col-12 mb-2"
          label="New Password"
          name="newPassword"
          :type="showPassword ? 'text' : 'password'"
          :validators="[validator.required, validator.password]"
        >
          <template #append-icon>
            <span class="cursor-pointer" @click="showPassword = !showPassword">
              <i
                class="fa"
                :class="{
                  'fa-eye': !showPassword,
                  'fa-eye-slash': showPassword
                }"
              ></i>
            </span>
          </template>
        </fd-input>

        <div class="text-right pt-3">
          <button
            type="button"
            class="btn mr-1"
            @click="changePasswordModal.isShown = false"
          >
            Cancel
          </button>
          <button class="btn main">Update Password</button>
        </div>
      </fd-form>
    </modal>
  </div>
</template>

<script>
import TelInput from "@/modules/User/components/TelInput";

// Validator
import {
  required,
  password,
  email,
  phone
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
// API
import {
  projectMarketingStaff as projectMStaffAPI,
  developer as projectDeveloperAPI
} from "@/api";
// Model
import { ProjectMarketingStaffModel } from "@/models";
// Composable
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

const OWNER_ROLE_ID = 1;

export default {
  setup(props, context) {
    let toDeveloperUsers = () => {
      context.root.$router.push({
        name: "ManageDeveloperUsers",
        params: {
          id: this.$route.params.developerId
        }
      });
    };
    const submitHandlerOptions = {
      role: "developer user",
      successCallback: toDeveloperUsers
    };
    const { handleCreateSuccess, handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleCreateSuccess,
      handleSubmitError
    };
  },
  components: {
    TelInput,
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  props: {},
  data: function () {
    return {
      roleOptions: [],
      isRoleLoading: false,

      developerUser: {
        developer: {
          name: ""
        },
        rolePermission: {
          id: ""
        }
      },

      phoneInput: "",
      projectMarketingStaff: {
        email: "",

        name: "",
        phoneNo: {},
        developerId: "",
        roleId: ""
      },

      validator: {
        required: required,
        password: password,
        email: email,
        phone: phone
      },

      showPassword: false,

      changePasswordModal: {
        isShown: false,
        form: {
          passsword: ""
        }
      },

      OWNER_ROLE_ID
    };
  },
  computed: {
    isRoleEditable() {
      return this.developerUser.rolePermission?.id == OWNER_ROLE_ID;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await Promise.all([this.getProjectMStaff(), this.getRoleOptions()]);
      this.$store.commit("setIsLoading", false);
    },
    async getProjectMStaff() {
      try {
        let data = await projectMStaffAPI.getProjectMarketingStaff(
          this.$route.params.userId
        );
        this.developerUser = data;
        this.projectMarketingStaff =
          ProjectMarketingStaffModel.getMarketingStaffResponse(data);
        this.$refs.tel.setTel(this.projectMarketingStaff);
      } catch (error) {
        console.error(error);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get developer user. Please try again later."
        });
      }
    },
    async getRoleOptions() {
      try {
        this.isRoleLoading = true;
        let developerId = this.$route.params.developerId;
        let data = await projectDeveloperAPI.getDeveloperRoles(developerId);
        this.roleOptions = this._.cloneDeep(data.data);
        this.isRoleLoading = false;
      } catch (error) {
        this.isRoleLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get developer roles. Please try again later."
        });
      }
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        await projectMStaffAPI.updateProjectMarketingStaff(
          this.$route.params.userId,
          ProjectMarketingStaffModel.putMarketingStaffPayload(
            this.projectMarketingStaff
          )
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Developer user is updated successfully."
        });
        this.$router.push({
          name: "ManageDeveloperUsers",
          params: { developerId: this.$route.params.developerId }
        });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update developer user. Please try again later."
        });
        console.error(error);
      }
    },
    async changePassword() {
      try {
        this.$store.commit("setIsLoading", true);
        await projectMStaffAPI.updatePassword(
          this.$route.params.userId,
          this.changePasswordModal.form
        );
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Developer user's password has been updated successfully."
        });

        this.changePasswordModal.isShown = false;
        this.changePasswordModal.form.password = "";

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update the password. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency-users-edit {
  form {
    @extend %formDesign;
  }
  .change-password {
    min-width: 500px;
  }
}
</style>
